.crate_date_form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px; }

.date_picker {
  margin-left: 10px; }

.highlight {
  font-weight: bold;
  text-decoration: underline; }

.date_row {
  margin-bottom: 10px;
  width: 440px; }

.pagination {
  margin: 10px 0; }

.loading {
  opacity: 0.5;
  pointer-events: none; }

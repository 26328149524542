.table {
  font-size: 12px;
  display: grid;
  justify-items: stretch;
  align-items: stretch;
  position: relative;
  border-style: solid;
  border-color: #959595;
  border-width: 1px 0 1px 1px;

  & > * {
    box-sizing: border-box;
    padding: 8px 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-style: solid;
    border-color: #959595;
    border-width: 0 1px 0 0; } }

.csvButton {
  display: block;
  margin-bottom: 10px; }

.pagination {
  margin: 10px 0; }

.loading {
  opacity: 0.5;
  pointer-events: none; }

.filterField {
  background-color: none;
  border: 1px solid #959595;
  border-radius: 0;
  margin-left: 15px;
  flex: 1;
  box-sizing: border-box;
  padding: 0 5px; }

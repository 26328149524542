body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.disabled {
  pointer-events: none;
}

.hide {
  display: none;
}

.dx-texteditor.dx-editor-outlined {
  height: 40px;
  font-size: 1em;
  width: 300px;
}

.left-space {
  margin-right: 5px;
}

.float-right {
  float: right;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 8px;
  grid-auto-rows: minmax(100px, auto);
}

.sidebar {
  grid-column: 1 / 3;
  padding-block: 1rem;
}

.sidebar > div.nav {
  position: sticky;
  top: 0;
}

.content {
  grid-column: 3 / 13;
  padding-block: 1rem;
}

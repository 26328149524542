.tableHeader {
  color: #959595;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-width: 0 1px 1px 0 !important;
  box-shadow: none;

  &::first-child {
    border-left: none; }

  &.clickable {
    cursor: pointer; } }

.sortIcon {
  margin-left: 5px; }

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;

  button {
    font-size: 12px;
    border: none;
    cursor: pointer;
    padding: 6px 8px 7px 8px;
    margin-left: 4px;
    border-radius: 3px;
    pointer-events: all;
    width: 70px;

    &.selected {
      background-color: #d4d4d4;
      pointer-events: none; } } }

.label {
  color: #adadad;
  font-weight: 400;
  font-size: 12px; }

.space {
  padding: 10px;
}

.dx-datagrid-pager {
  padding: 12px 16px;
  background-color: #f8f9fa;
  border-top: 1px solid #dee2e6;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dx-pager .dx-pages .dx-page {
  padding: 6px 12px;
  margin: 0 2px;
  border-radius: 4px;
}

.dx-pager .dx-pages .dx-page.dx-selection {
  background-color: #007bff;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dx-pager .dx-page-sizes .dx-page-size {
  padding: 6px 12px;
  margin: 0 2px;
  border-radius: 4px;
}

.underline-bold {
  font-weight: bolder;
  font-size: 40px;
  color: #ec1e23;
}

.active {
  color: #ec1e23;
}

.nav-item {
  font-weight: bold;
  color: black;
  text-transform: uppercase;
  cursor: pointer;
}

.swal-footer {
  text-align: center;
}

.account-active {
  color: gray;
}

.account-inactive {
  color: red;
}
